(() => {
  let options = {
    root: null,
    rootMargin: "100px 0px 100px 0px ",
    threshold: [0],
  };
  let menuItems;
  let observers = [];
  let currentNavId;

  const resizeObserver = new ResizeObserver((entries) => {
    observers.forEach((observer) => observer.disconnect());
    observers = [];
    const width = entries[0].contentRect.width;
    if (width <= 767) {
      menuItems = document.querySelectorAll(
        "#flyout-menu a, #mobile-logo-link"
      );
      options.threshold = 0.1;
      currentNavId = "flyout-menu";
    } else {
      menuItems = document.querySelectorAll(
        "#topbar-menu a[href^='#'], #desktop-logo-link"
      );

      currentNavId = "topbar-menu";
    }

    menuItems.forEach((linkElement) => {
      const targetId = linkElement.getAttribute("href").split("#")[1];
      const sectionHeight = document.getElementById(targetId).offsetHeight;
      const viewportHeight = window.innerHeight + 200;
      options.threshold = (viewportHeight * 0.25) / sectionHeight;
      if (targetId === "services") {
        options.threshold = 0.1;
      }

      if (targetId === "locations") {
        if (width <= 320) {
          options.threshold = 0.2;
        } else if (width < 640) {
          options.threshold = 0.3;
        } else if (width <= 767) {
          options.threshold = 0.5;
        } else {
          options.threshold = 0.7;
        }
      }

      const targetElement = document.getElementById(targetId);
      if (!targetElement) return;
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        let activeMenuItem;

        if (entry.isIntersecting) {
          if (currentNavId === "flyout-menu" && entry.target.id === "welcome") {
            activeMenuItem = document.querySelector("#mobile-logo-link");
          } else if (
            currentNavId === "topbar-menu" &&
            entry.target.id === "welcome"
          ) {
            activeMenuItem = document.querySelector("#desktop-logo-link");
          } else {
            activeMenuItem = document.querySelector(
              `#${currentNavId}  li a[href="#${entry.target.id}"]`
            );
          }

          menuItems.forEach((menuItem) => {
            menuItem.parentElement.classList.remove("active");
          });

          activeMenuItem.parentElement.classList.add("active");
          window.history.replaceState({}, "", `#${entry.target.id}`);
        }
      }, options);
      observer.observe(targetElement);
    });
  });

  resizeObserver.observe(document.body);

  window.addEventListener("unload", () => {
    resizeObserver.disconnect();
    observers.forEach((observer) => observer.disconnect());
  });
})();
